<template>
  <div class="loading" />
</template>

<script>
export default {
  name: 'BaseLoading',
  computed: {
    classes() {
      return {
        loading: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes pulse {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
.loading {
  background: linear-gradient(270deg, $dark-grey, $grey);
  background-size: 400% 400%;
  animation: pulse 1.5s ease infinite;
  display: inline-block;
  width: 100%;
  height: 100%;
}
</style>
