<template>
  <input
    v-model="controlledInput"
    class="base-input"
    :type="type"
    :placeholder="placeholder"
    :aria-label="label"
    :min="min"
    :max="max"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
    @keydown="$emit('keydown')"
    @keypress="$emit('keypress')"
    @keyup="$emit('keyup')"
    @paste="handlePaste"
  >
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
      default: () => {
        return '';
      },
    },
    label: {
      type: String,
      required: false,
      default: () => {},
    },
    min: {
      type: Number,
      required: false,
      default: () => {},
    },
    max: {
      type: Number,
      required: false,
      default: () => {},
    },
  },
  computed: {
    controlledInput: {
      get() {
        return this.value;
      },
      set(controlledInput) {
        this.$emit('input', controlledInput);
      },
    },
  },
  methods: {
    handlePaste(e) {
      this.$emit('input', e.clipboardData.getData('text'));
    },
  },
};
</script>

<style lang="scss" scoped>
  .base-input {
    width: 100%;
    height: 42px;
    margin: 0;
    padding: 0 0 0 15px;
    box-sizing: border-box;
    border: 1px solid rgb(220, 223, 230);
    border-radius: 4px;
    color: rgb(96, 98, 102);
    font-size: 16px;
    &::placeholder {
      color: rgb(196, 196, 196);
    }
    &:hover {
      border: 1px solid rgb(196, 196, 196);
    }
    &:focus {
      outline: none;
      border: 1px solid $orange;
    }
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
</style>
