<template>
  <div class="modal">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseModal",
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #374458b8;
  backdrop-filter: blur(8px);
}
</style>
