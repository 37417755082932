<template>
  <div class="list-card" :class="{ 'no-hover': noHover }" @click="$emit('list-card-click')">
    <!-- <div class="top"> -->
    <slot name="custom-content" />
    <div class="left">
      <slot name="left" />
    </div>
    <div class="right">
      <slot name="right" />
    </div>
    <!-- </div> -->
    <slot name="bottom" class="bottom" />
  </div>
</template>

<script>
export default {
  name: "BaseListCard",
  props: {
    noHover: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.list-card {
  display: flex;
  padding: 15px 20px;
  border-radius: 8px;
  flex-wrap: wrap;
  background-color: #ffffff;
  font-size: 14px;
  margin-bottom: 5px;
  transition: all 0.2s ease;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
    border-color: #afafaf;
  }
}
.top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.no-hover {
  &:hover {
    cursor: default;
    border: 1px solid #e6e6e6;
  }
}
.left {
  display: flex;
  align-items: center;
}
.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.list-card.patient-chart {
  .right {
    width: 20%;
  }
}
</style>
