<template>
  <div
    class="no-data"
    :class="{darkbg: dark}"
  >
    <p class="message">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: 'BaseNoData',
  props: {
    dark: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .no-data {
    background-color: #ecedf1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  .message {
    color: #909399;
    font-size: 12px;
  }
  .darkbg {
    background-color: #f5f7fa;
  }
</style>
